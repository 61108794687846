import "../styles/global.css";

function MyApp(props: any) {
  const {
    Component,
    pageProps: { ...pageProps },
  } = props;

  return (
    <div>
      <Component {...pageProps} />
    </div>
  );
}

export default MyApp;
